<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'Material',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
